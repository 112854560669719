/* Filename: ./components/ToggleSwitch.css */

.container {
  display: flex; /* Use flexbox for layout */
  flex-direction: row; /* Set the direction of items in a row */
  justify-content: flex-end; /* Align items at the end of the container */
  background-color: #ff00ff;
}


.toggle-switch {
  position: relative;
  width: 130px;
  display: inline-block;
  text-align: left;
  margin-right: 10px; /* Add some spacing between switch and label */
}

.checkbox {
  display: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}

.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}

.inner:before {
  content: "Show Berth";
  padding-left: 10px;
  background-color: #22418a;
  color: #fff;
}

.inner:after {
  content: "Hide Berth";
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}

.switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 96px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.checkbox:checked + .label .inner {
  margin-left: 0;
}

.checkbox:checked + .label .switch {
  right: 0px;
}

.label-right {
  /* Style the label on the right */
  line-height: 36px; /* Align vertically with the switch */
}