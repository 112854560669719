* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5); /* Transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    width: 45px;
    aspect-ratio: .75;
    --c: no-repeat linear-gradient(#000 0 0);
    background: 
        var(--c) 0%   50%,
        var(--c) 50%  50%,
        var(--c) 100% 50%;
    animation: l7 1s infinite linear alternate;
}

@keyframes l7 {
    0%  {background-size: 20% 50% ,20% 50% ,20% 50% }
    20% {background-size: 20% 20% ,20% 50% ,20% 50% }
    40% {background-size: 20% 100%,20% 20% ,20% 50% }
    60% {background-size: 20% 50% ,20% 100%,20% 20% }
    80% {background-size: 20% 50% ,20% 50% ,20% 100%}
    100%{background-size: 20% 50% ,20% 50% ,20% 50% }
}
