.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.tide-table {
    width: 100%;
    max-width: 500px;
    border-collapse: collapse;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    border: 2px solid #22418a;
}

.tide-table th,
.tide-table td {
    border: 1px solid #22418a;
    padding: 8px;
    font-size: 30px;
}

.table-title {
    background-color: #22418a;
    color: white;
    font-size: 35px;
    font-weight: bold;
    padding: 10px;
}

.tide-table th {
    background-color: #0074cc;
    color: white;
}

.tide-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.tide-table tr:hover {
    background-color: #d0e7ff;
}