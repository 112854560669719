/* General */
/* General */
/* General */

.table-container .table tbody tr.row-white td {
  background-color: white !important;
}

.table-container .table tbody tr.row-lightgreen td {
  background-color: lightgreen !important;
}

.table-container .table tbody tr.row-lightyellow td {
  background-color: lightyellow !important;
}

.table-container .table tbody tr.row-lightcoral td {
  background-color: lightcoral !important;
}

.primaryColorIcon {
  background-color: #22418a;
  hover: #22418a;

  color: #22418a !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-body {
  background-color: #cadcfc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4000px !important;
}

select {
  font-family: "Montserrat", sans-serif;
}

option {
  font-family: "Montserrat", sans-serif;
}

/* password validator */
/* password validator */
/* password validator */

.tracker-box {
  background-color: #386489;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.09em;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tracker-box div {
  margin: 5px 0;
}

.tracker-box .validated {
  color: rgba(255, 255, 255, 0.5);
}

.list-icon {
  margin-right: 10px;
}

.list-icon.green {
  color: #0f0;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-container input {
  width: 100%;
  padding-right: 2.5rem;
}

.icon-span {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-validator {
  margin-bottom: 15px;
}

/* all buttons and their icons */
/* all buttons and their icons*/
/* all buttons and their icons*/

.btn.gangSave {
  border-color: #22418a !important;
  margin: 5px;
  color: #003868;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-left: 10px;
  margin-bottom: 10px;
}

.btn.gangClose {
  border-color: orange !important;
  margin: 5px;
  color: orange;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-left: 10px;
  margin-bottom: 10px;
}

.btn.gangDelete {
  border-color: #8a2222 !important;
  margin: 5px;
  color: #7a1b1b;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-left: 10px;
  margin-bottom: 10px;
}

.btn.gangReserve {
  /* margin: 5px; */
  font-size: 22px;
  color: #1a8a22;
  margin-top: 20px;
}

.btn.gangNoReserve {
  margin: 3px;
  font-size: 22px;
  color: #8a2224;
}

.btn.navHome {
  margin: 5px;
  color: #003868;
  margin-right: 35px;
  background-color: white;
}

.homeIcon {
  font-size: 24px;
}

.btn.navBookings {
  margin: 5px;
  color: #003868;
  background-color: white;
}

.bookingsIcon {
  font-size: 24px;
}

.btn.navVessels {
  margin: 5px;
  color: #003868;
  background-color: white;
}

.vesselsIcon {
  font-size: 24px;
}

.btn.navOperations {
  margin: 5px;
  color: #003868;
  background-color: white;
}

.operationsIcon {
  font-size: 24px;
}

.addButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.viewInOperations {
  background-color: #22418a;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  border: 2px solid #003868;
  margin-right: 10px;
}

.btn.viewInOperationsCalendar {
  background-color: #22418a;
  color: white;
  font-family: "Montserrat", sans-serif;

  border: 2px solid #22418a;
}

.btn.addingBooking {
  background-color: #22418a;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  border: 2px solid #003868;
  margin-right: 10px;
}

.btn.cancelBooking {
  background-color: #ffc722;
  color: black;
  border: 2px solid #e5b100;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.btn.deleteBooking {
  background-color: #8a2222;
  color: white;
  border: 2px solid #7a1b1b;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-right: 10px;
}

.btn.navOperationsMap {
  margin: 5px;
  color: #003868;
  background-color: white;
}

.operationsMapIcon {
  font-size: 24px;
  background-color: white;
}

.btn.navOperationsMapCalendar {
  margin-left: 15px;
  color: #003868;
  background-color: white;
}

.operationsMapIconCalendar {
  font-size: 20px;
  background-color: white;
}

.btn.reports {
  margin: 5px;
  color: #003868;
  background-color: white;
}

.reportsIcon {
  font-size: 24px;
}

.btn.addToReports {
  margin: 5px;
  color: #003868;
  margin-left: 100px;
}

.addToReportsIcon {
  font-size: 24px;
}

.btn.login {
  margin: 5px;
  color: #003868;
}

.loginIcon {
  font-size: 24px;
}

.btn.logout {
  margin: 5px;
  color: #003868;
  background-color: white;
}

.logoutIcon {
  font-size: 24px;
}

.btn.edit {
  margin: 3px;
  color: #003868;
}

.btn.save {
  margin: 3px;
  color: #003868;
}

.editIcon {
  font-size: 20px;
}

.btn.delete {
  margin: 3px;
  color: #003868;
}

.deleteIcon {
  font-size: 20px;
}

.btn.addBooking {
  margin: 5px;
  color: #003868;
}

.addBookingIcon {
  font-size: 34px;
}

.calendarViewIcon {
  font-size: 34px;
}

.listViewIcon {
  font-size: 34px;
}

.btn.allYears {
  border-color: #003868 !important;
  margin: 5px;
  color: #003868;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-left: 10px;
  margin-bottom: 10px;
}

.btn.thisYear {
  border-color: #003868 !important;
  margin: 5px;
  color: #003868;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-left: 10px;
  margin-bottom: 10px;
}

.btn.today {
  border-color: #003868 !important;
  margin: 5px;
  color: #003868;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-left: 10px;
  margin-bottom: 10px;
}

.btn.previous {
  margin: 5px;
  color: #003868;
}

.previousIcon {
  font-size: 24px;
}

.btn.next {
  margin: 5px;
  color: #003868;
}

.nextIcon {
  font-size: 24px;
}

.btn:disabled {
  border: none;
}

.customToggler {
  margin: 5px;
  font-size: 24px;
  color: #003868;
  border-color: #003868 !important;
  border: 2px solid;
  background-color: white;
}

.btn.toggleCancelled {
  margin: 5px;
  color: #003868;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.eyeIcon {
  font-size: 34px;
}

.top-container-inside .bookingPageTooltip {
  background-color: #22418a;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.navbar-collapse .headerTooltip {
  background-color: #22418a;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.navbar-collapse .header2Tooltip {
  background-color: #22418a;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.operationsPictureBookingTopContainer .operationsMapTableTooltip {
  background-color: #22418a;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.iconContainer .operationsMapGraphModalTooltip {
  background-color: #22418a;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.row .operationsMapAddGangwayReservationFormTooltip {
  background-color: #22418a;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}

/* modals */
/* modals */
/* modals */

.dayReportsHeader {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #003868 !important;
}

.DayReportsContainerInterior {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalContainer-DayReport {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 20;
  /* Ensure it's on top */
  backdrop-filter: blur(5px);
  /* Blur the background */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
}

.pdfContainer-DayReport {
  width: 90%;
  height: 90%;
  z-index: 40;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pdfButtons {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.pdfReportAndButtons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pdfButtonsContainer {
  position: absolute;
  top: 10px;
  /* Adjust top spacing */
  right: 10px;
  /* Align to the right */
  display: flex;
  gap: 10px;
  /* Space between buttons */
}

.btn.closePDFDownload {
  background-color: #ffc722;
  color: black;
  border: 2px solid #e5b100;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 10px;
}

.btn.cancelPDFDownload {
  background-color: #8a2222;
  color: white;
  border: 2px solid #7a1b1b;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 10px;
}

.btn.downloadPDF {
  background-color: #22418a;
  color: white;
  border: 2px solid #003868;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 10px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.reportContainer-DayReport {
  width: 80%;
  height: 80%;
  overflow-y: hidden;
  z-index: 1;
  position: absolute;
  top: -5000px;
  left: -5000px;
}

.firstDiv-DayReport {
  display: flex;
  padding: 10px;
  background-color: white;
  width: 50%;
}

.secondDiv-DayReport {
  background-color: lightblue;
  align-items: center;
  justify-content: center;
}

.stageContainer {
  display: flex;
  background-color: lightcoral;
  transform: scale(0.6);
  align-items: center;
  justify-content: center;
}

.reportChartFirstContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.reportChartSecondContainer {
  display: flex;
  position: relative;
  width: 80%;
  height: 50%;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .reportGraphTitle{
  text-align: center;
 
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  color: #1b2a4e !important;
  padding-top: 3px;
  font-weight: bold;
} */

.reportTideFirstContainer {
  display: flex;
  position: relative;
  width: 50%;
  height: 50%;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reportGraphTable table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.reportGraphTable th,
.reportGraphTable td {
  padding: 10px 10px;
  border: 1px solid #1b2a4e;
}

.reportGraphTableHeading {
  color: #1b2a4e !important;
}

.reportGraphTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.required {
  color: #7a1b1b;
}

.is-invalid {
  border-color: #7a1b1b;
}

.invalid-feedback {
  border-color: #8a2222;
}

.modalLables {
  color: #003868 !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.modalDisplay {
  font-family: "Montserrat", sans-serif;
}

.modal-title {
  font-family: "Montserrat", sans-serif;
  color: #003868 !important;
  font-weight: 700;
}

.modal-body {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: left;
}

.modalCancel {
  background-color: white;
  font-family: "Montserrat", sans-serif;
  border: none;
  font-size: 40px;
  position: absolute;
  top: 0px;
  right: 10px;
  color: #003868 !important;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

/* Container for the modal and background overlay */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  /* Ensure it's on top */
  backdrop-filter: blur(5px);
  /* Blur the background */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
}

/* Modal styling */
#bookingsUpdateModal {
  background: #fff;
  /* White background */
  padding: 20px;
  border-radius: 10px;
  /* Less rounded corners */
  box-shadow: 0 4px 8px rgba(34, 65, 138, 0.2);
  border: 2px solid #ccc;
  z-index: 10;
  /* Ensure it's above the blurred background */
  width: 50%;
  /* Width of the modal */
  max-width: 600px;
  /* Maximum width */
  box-sizing: border-box;
  /* Ensure padding is included in width */
}

/* Ensure buttons fit within the modal */
.bookingsUpdateModal input,
.bookingsUpdateModal textarea,
.bookingsUpdateModal button {
  display: block;
  width: calc(100% - 40px);
  /* Account for padding */
  margin: 0 auto 10px auto;
  /* Center elements and add margin */
  padding: 10px;
  box-sizing: border-box;
  /* Include padding in width */
}

/* Optional: Specific button styling */
.bookingsUpdateModal button {
  width: auto;
  /* Allow button to auto-size */
  padding: 10px 20px;
  /* Add padding for better appearance */
}

/* booking tables */
/* booking tables */
/* booking tables */

.table-container {
  display: block;
  justify-content: center;
  /* width: 90%; */
  margin: 20px;
  align-items: center;
  box-shadow: 0 4px 8px rgba(34, 65, 138, 0.3);
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 500px;
  overflow-y: auto;
}

table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.top-container {
  width: 90%;
  margin: 0 auto;
  align-items: center;
  box-shadow: 0 4px 8px rgba(34, 65, 138, 0.3);
  margin-top: 20px;
  margin-bottom: 30px;
}

.top-container-inside {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.top-container-interior {
  display: flex;
  align-items: center;
}

td {
  width: auto;
  align-content: center;
  font-family: "Montserrat", sans-serif;
}

th {
  background-color: white !important;
  color: #003868 !important;
  align-content: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

td.min,
th.min {
  width: 15% !important;
  white-space: nowrap !important;
}

.cancelled-row {
  text-decoration: line-through;
}

/* badges in bookings */
/* badges in bookings*/
/* badges in bookings*/

.badge {
  font-size: 1em;
}

.badge-success {
  background-color: #28a745;
}

.badge-warning {
  background-color: #ffc107;
  color: #212529;
}

.badge-danger {
  background-color: #dc3545;
}

/* bookings */
/* bookings */
/* bookings */

.pagination {
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.bookingLabels {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #003868 !important;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.bookingHeader {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #003868 !important;
  margin-left: 10px;
}

.selectedDate {
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  border-radius: 8px;
  border: 1px solid #003868;
}

.selectedYear {
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #003868;
  width: 90px;
  font-size: 15px;
}

.globalFilter {
  font-family: "Montserrat", sans-serif;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #003868;
}

/* header */
/* header */
/* header */

.navbar-nav {
  flex-grow: 1;
  justify-content: flex-start;
}

.navbar-nav2 {
  flex-grow: 1;
  justify-content: flex-start;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar-nav .nav-item:last-child {
  margin-left: auto;
}

.transparentHeader {
  background-color: rgba(255, 255, 255, 0);
  /* Set to a transparent white */
  position: absolute;
  /* Fix it to the top */
  top: 0;
  /* Align to the top */
  left: 0;
  /* Align to the left */
  right: 0;
  /* Align to the right */
  z-index: 10;
  /* Ensure it stays on top of other elements */
  width: 5%;
}

.custom-toggler {
  background-color: white;
  /* Set background to white */
  border: none;
  /* Remove border if necessary */
}

.custom-toggler .navbar-toggler-icon {
  background-color: blue;
  /* Set the icon background color to blue */
}

/* Optional: If you want to remove the default icon color */
.custom-toggler:focus {
  outline: none;
  /* Remove the default focus outline */
}

/* operations */
/* operations */
/* operations */

.operationsContainer {
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  /*filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));*/
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
  margin-top: 10px;
  margin-bottom: 10px;
}

.operationsContainerWrapper {
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  /*filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));*/
  box-shadow: 0 4px 8px rgba(34, 65, 138, 0.3);
  margin-top: 10px;
  margin-bottom: 10px;
}

.operationsTopHeader {
  text-align: center;
  margin: 10px;
  color: #003868;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.operationsTopList {
  justify-content: center;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.operationsTopLabels {
  text-align: center;
  color: #003868 !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 10px;
  margin-left: 20px;
}

.operationsTopText {
  margin: 10px;
  margin-left: 0px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.operationsList {
  justify-content: center;
  width: 90%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.operationsListBorder {
  justify-content: center;
  width: 90%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 4px solid #7a1b1b;
  /* Yellow with 50% opacity */
}

.operationsLabels {
  text-align: center;
  color: #003868 !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 10px;
}

.operationsText {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.customTooltip {
  display: flex;
  flex-direction: column;
  color: #003868 !important;
  font-family: "Montserrat", sans-serif;
  background-color: white;
  border: 1px solid #003868;
  border-radius: 4px;
  padding: 2px;
}

.calculationErrorMessage {
  color: #7a1b1b;
  font-family: "Montserrat", sans-serif;
}

.operationsTopContainer {
  display: flex;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  box-shadow: 0 4px 8px rgba(34, 65, 138, 0.3);
  margin-top: 20px;
  margin-bottom: 30px;
}

.operationsTopContainerInterior {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* operations map */
/* operations map */
/* operations map */

.operationsPictureTopContainer {
  position: relative;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  box-shadow: 0 4px 8px rgba(34, 65, 138, 0.3);
  margin-top: 10px;
  margin-bottom: 10px;
}

.operationsPictureTopContainerInterior {
  display: flex;
  align-items: center;
  justify-content: center;
}

.operationsMapContainerInterior {
  display: flex;
  align-items: center;
  justify-content: center;
}

#capture-container {
  position: relative;
  margin: auto;
  box-sizing: border-box;
}

.operationsPictureShowGraphs {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: px;
  padding: 20px 0;
  border: 0px solid transparent;
  position: absolute;
  background-color: white;
  z-index: 10;
  cursor: "move";
  max-height: 600px;
  border: 2px solid #22418a;
  border-radius: 20px;
}

.operationsMapTideTopContainer {
  position: absolute;
  width: 17%;
  margin: 0 auto;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 30px;
  z-index: 1;
  background-color: white;
  border-radius: 20px;
  border: 2px solid #22418a;
  right: 1%;
}

/* testing2 */
/* testing2 */
/* testing2 */

.operationsListTesting {
  justify-content: center;
  width: 90%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.operationsListTesting1 {
  justify-content: center;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.operationsListTesting2 {
  justify-content: center;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.operationsListBorderTesting {
  justify-content: center;
  width: 90%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  /* border: 4px solid yellow; */
  background-color: rgba(255, 255, 0, 0.1);
  /* Yellow with 50% opacity */
}

.testingTop {
  display: flex;
  align-items: center;
}

.testing1 {
  display: flex;
  width: 10%;
  justify-content: center;
  align-items: center;
}

.testing2 {
  width: 30%;
  display: flex;
  justify-content: center;
}

.graphModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  /* Ensure it's on top */
  backdrop-filter: blur(5px);
  /* Blur the background */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
}

/* Modal styling */
.operationsGraphModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #ccc;

  width: 85%; /* Adjusted width */
  height: 60%; /* Adjusted height */
  max-width: 1200px; /* Added max width for larger screens */
  box-sizing: border-box;
  box-sizing: border-box;
  position: relative; /* Needed for absolute positioning of close button */
}

.chartContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 60%;
}

.iconContainer button {
  margin-left: 5px;
}

.iconContainer {
  display: flex;
  flex-direction: row; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  /* margin-top: 10px; */
  padding: 10px;
  text-align: center;
}

.iconContainer p {
  margin-bottom: 10px; /* Add spacing between text and buttons */
}
/* .gangAdd {
  display: flex;
  position: relative;
  width: 90%;
  height: 80%;
} */
.graphModalCancel {
  position: absolute;
  top: 0px;
  right: 10px;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  border: none;
  font-size: 40px;
  color: #003868 !important;
}

.graphModalCancel2 {
  position: absolute;
  top: 16.5%;
  right: 16.5%;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  border: none;
  font-size: 40px;
  cursor: pointer;
}

.graphModalAddToReports {
  background-color: white;
  font-family: "Montserrat", sans-serif;
  border: none;
  font-size: 40px;
  color: #003868 !important;
}

.operationsListModal {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1200px; /* Added max width for consistency */
  align-items: stretch; /* Stretch items to fill container width */
  margin: 0 auto; /* Center horizontally */
}

.operationsListModalBorder {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1200px; /* Added max width for consistency */
  align-items: stretch; /* Stretch items to fill container width */
  margin: 10px auto; /* Center horizontally with margin */
  border: 4px solid #8a2222;
  z-index: 10;
}

.chartTitle {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  color: #003868 !important;
  padding-top: 3px;
  font-weight: bold;
}

.pictureGraphModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
}

/* booking tables */
/* booking tables */
/* booking tables */

.operationsPictureBookingTableContainer {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  box-shadow: 0 4px 8px rgba(34, 65, 138, 0.3);
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 10;
  margin-left: 3%;
}

.operationsPictureBookingTopContainer {
  position: absolute;
  width: 40%;
  margin: 0 auto;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 30px;
  z-index: 1;
  background-color: white;
  border-radius: 20px;
  border: 2px solid #22418a;
  left: 4%;
}

.operationsPictureBookingTopContainerInside {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  z-index: 10;
}

.operationsPictureBookingTopContainerInterior {
  display: flex;
  align-items: center;
  z-index: 10;
}

.operationsPictureBookingTd {
  width: auto;
  align-content: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.operationsPictureBookingTh {
  background-color: white !important;
  color: #003868 !important;
  align-content: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
}

td.min,
th.min {
  width: 15% !important;
  white-space: nowrap !important;
}

.cancelled-row {
  text-decoration: line-through;
}

.operationsPictureBookingTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: px;
  width: 700px;
  height: 800px;
  padding: 20px 0;
  border: 0px solid transparent;
  position: absolute;
  background-color: white;
  z-index: 10;
}

/* reports */
/* reports */
/* reports */

.reportsContainer {
  display: flex;
  margin-top: 20px;
  padding: 10px;
}

.reportsContainerInterior {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reportsContainerInputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 20;
  width: 75%;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
}

.reportsContainerTextInput {
  width: 70%;
  margin: 5px;
  border: 1px solid #22418a !important;
}

.reportsImageCard {
  background-color: white;
  border: 1px solid #22418a !important;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(34, 65, 138, 0.3);
  padding: 5px;
  text-align: center;
  width: 100%;
  margin: 10px;
}

.reportsImageThumbnail {
  width: 200px;
  height: auto;
  border-radius: 5px;
}

.reportsImageThumbnail:hover {
  transform: scale(1.05);
}

.reportsContainerInterior ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.reportsContainerInterior li {
  margin: 22;
  padding: 10;
}

.reportsHeader {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #003868 !important;
}

/* Gangway Reservations */
/* Gangway Reservations */
/* Gangway Reservations */

.gangwayReservationModalInterior {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(34, 65, 138, 0.2);
  border: 2px solid #ccc;
  z-index: 10;
  width: 50%;
  max-width: 600px;
  box-sizing: border-box;
}

.operationsMapGangwayReservationsContainer {
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  border: 2px solid #22418a;
  /* overflow: auto; */
  left: 45%;
  background-color: white;
  margin-top: 1%;
  margin-left: 1%;
}

.reserveHeader {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #003868 !important;
}

.arrow_box {
  z-index: 1000;
  font-size: 12px;
  color: #22418a !important;
}

.rdtPicker {
  border-radius: 20px;
  border: 1px solid #22418a !important;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  color: #003868 !important;
}

/* Login Page/Landing Page */
/* Login Page/Landing Page */
/* Login Page/Landing Page */

.loginContainer1 {
  position: relative;
  background-color: #fdfcfc;
}

.loginContainer2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 20px;
  height: 10vh;
  border-bottom: 1px solid #d3d3d3;
}

.loginContainer3 {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

.loginContainer3 img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 0;
}

.loginContainer3 img.active {
  opacity: 1;
  z-index: 1;
}

.loginContainer4 {
  position: relative;
  text-align: center;
}

.loginOverlay {
  position: absolute;
  z-index: 20;
  background-color: black;
  opacity: 0.65;
  color: white;
  left: 10vh;
  width: 50vh;
  height: 35vh;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.textGroup {
  position: absolute;
  z-index: 20;
  color: white;
  left: 12vh;
  width: 60vh;
  height: 40vh;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
}

.loginText1 {
  padding-bottom: 10px;
  padding-top: 20px;
}

.loginText {
  padding-top: 10px;
}

.btn.loginButton {
  z-index: 1;
  color: white;
  background-color: #b02e2e;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: bold;
  padding: 15px;
  padding-left: 125px;
  padding-right: 125px;
}

.mloginButton:hover {
  background-color: #8b2323;
}

.btn.menuButton {
  color: #003868;
  font-family: "Montserrat", sans-serif;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menuButton:hover {
  background-color: lightblue;
}

.loginHeading {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 24px;
  color: #003868;
  font-size: 32px;
  font-weight: bold;
  padding-left: 8%;
}

.buttonGroup {
  display: flex;
  gap: 20px;
  padding-right: 10%;
}

/* Login2 Page/Landing Page */
/* Login2 Page/Landing Page */
/* Login2 Page/Landing Page */

.login2Container1 {
  position: relative;
  background-color: #fdfcfc;
}

.login2Container2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 20px;
  height: 10vh;
  border-bottom: 1px solid #d3d3d3;
}

.login2Container3 {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

.login2Container3 img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 0;
}

.login2Container3 img.active {
  opacity: 1;
  z-index: 0;
}

.login2Container4 {
  position: relative;
  text-align: center;
}

.login2Overlay {
  position: absolute;
  z-index: 20;
  background-color: black;
  opacity: 0.65;
  color: white;
  left: 10vh;
  width: 50vh;
  height: 35vh;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.textGroup2 {
  position: absolute;
  z-index: 20;
  color: white;
  left: 12vh;
  width: 60vh;
  height: 40vh;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
}

.login2Text1 {
  padding-bottom: 10px;
  padding-top: 20px;
}

.login2Text {
  padding-top: 10px;
}

.btn.login2Button {
  z-index: 20;
  color: white;
  background-color: #b02e2e;
  /* background-color: #578ab4; */
  /* background-color: white; */
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: bold;
  padding: 15px;
  padding-left: 125px;
  padding-right: 125px;
  position: absolute;
  top: 70%;
  /* Adjust position as needed */
  left: 50%;
  transform: translate(-50%, -50%);
}

.mlogin2Button:hover {
  background-color: #8b2323;
}

.btn.menuButton2 {
  color: #003868;
  font-family: "Montserrat", sans-serif;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menuButton2:hover {
  background-color: lightblue;
}

.login2Heading {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 24px;
  color: #003868;
  font-size: 32px;
  font-weight: bold;
  padding-left: 8%;
}

.buttonGroup2 {
  display: flex;
  gap: 20px;
  padding-right: 10%;
}

/* Login3 Page/Landing Page */
/* Login3 Page/Landing Page */
/* Login3 Page/Landing Page */

.login3Container1 {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #fdfcfc;
  overflow: hidden;
}

.login3Container2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 20px;
  height: 10vh;
  border-bottom: 1px solid #d3d3d3;
}

.login3Video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the video covers the whole section */
  z-index: 0;
  /* Places the video in the background */
  filter: brightness(100%);
}

.login3Container2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 20px;
}

.login3Container3 {
  z-index: 20;
  color: white;
  top: 70%;
  /* Adjust position as needed */
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn.login3Button {
  z-index: 20;
  color: white;
  background-color: #b02e2e;
  /* background-color: #578ab4; */
  /* background-color: white; */
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: bold;
  padding: 15px;
  padding-left: 125px;
  padding-right: 125px;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mlogin3Button:hover {
  background-color: #8b2323;
}

.btn.menuButton3 {
  color: White;
  font-family: "Montserrat", sans-serif;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menuButton3:hover {
  background-color: lightblue;
}

.login3Heading {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 24px;
  color: White;
  font-size: 32px;
  font-weight: bold;
  padding-left: 8%;
}

.login3Heading2 {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 24px;
  color: White;
  font-size: 40px;
  font-weight: bold;

  top: 65%;
}

.login3Heading3 {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 24px;
  color: White;
  font-size: 30px;
  font-weight: bold;

  top: 65%;
}

.buttonGroup3 {
  display: flex;
  gap: 20px;
  padding-right: 10%;
}

.buttonGroup3,
.login3Heading,
.login3Heading2,
.login3Heading3,
.btn.login3Button {
  position: relative;
  z-index: 10;
}

/* Bookings Page Calendar */
/* Bookings Page Calendar */
/* Bookings Page Calendar */

.calendar-container {
  display: block;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  box-shadow: 0 4px 8px rgba(34, 65, 138, 0.3);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 600px;
}

.rbc-toolbar-label {
  color: #22418a !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.rbc-btn-group {
  color: #22418a !important;
  font-family: "Montserrat", sans-serif;
}

.rbc-row {
  color: #22418a !important;
  font-family: "Montserrat", sans-serif;
}

.rbc-event {
  font-family: "Montserrat", sans-serif;
  padding: 0 !important;
  background-color: white;
}

.rbc-event-content {
  font-family: "Montserrat", sans-serif;
  padding: 0 !important;
  background-color: white;
}

/* Data Entry Page */
/* Data Entry Page */
/* Data Entry Page */

.data-entry-container {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.door-info {
  margin-top: 20px;
}

.dataEntryRows {
  display: flex;
  flex-wrap: wrap;
  /* Ensures items wrap to the next row if space is insufficient */
  gap: 2px;
  /* Adds spacing between items */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.field {
  display: flex;
  flex-direction: column;
  /* Stacks label on top of input or div */
  min-width: 150px;
  /* Prevents fields from shrinking too much */
}

.field label {
  font-weight: bold;
  margin-bottom: 5px;
}

.field input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.bookingTideTopContainer {
  position: fixed;
  z-index: 20;
  background-color: white;
  border-radius: 20px;
  border: 2px solid #22418a;
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 50%;
  align-items: center;
   /* Center content horizontally within the container */
  /* justify-content: center; */ /* Center content vertically within the container */
}
.bookingTideGraphModal {
  display: flex;
  position: relative;
  width: 100%;
  height: 70%;
}
/* Reports2 */
/* Reports2 */
/* Reports2 */
.bookingTideTitle {
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  border: none;
  font-size: 30px;
  color: #003868 !important;
}

/*  */
.arrival-section {
  display: flex;
  justify-content: space-between;
  align-items: center; /* keep this for horizontal alignment */
  padding: 10px;
  color: black;
  font-weight: bold;
  width: 90%; /* Adjust as needed */
}

.arrival-box {
  flex: 1;
  text-align: center;
}

.arrival-center {
  flex: 2;
}

.arrival-ship-name {
  display: block;
  font-size: 12px;
  font-weight: normal;
}

.hvvwTideGraphModal {

  width: 100%;
  height: 60%;
  /* padding: 20px; */
  background-color: white;
}

.hvvwTideGrapContainer {
  position: absolute;
  margin: 0 auto;
  align-items: center;
  /* margin-top: 1%; */
  /* margin-bottom: 30px; */
  z-index: 20;
  /* border-radius: 20px; */
  /* border: 2px solid #22418a; */
  right: 1%;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  width: 40%;
  height: 40%; 
  background-color: white;
}

.hvvwTideGraphTideTitle {
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  border: none;
  font-size: 20px;
  color: #003868 !important;
   background-color: white;
   /* padding-bottom: 10px; */
}

.hvvw-title-section {
  display: flex;
  justify-content: center;
  align-items: center; /* keep this for horizontal alignment */
  padding: 10px;
  color: black;
  font-weight: bold;
  /* width: 90%;  */
  /* Adjust as needed */
}

.next-button {
  position: absolute;
  bottom: 0px;
  right: 10px;
  height: 50px;
  margin: 10px;
}